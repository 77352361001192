import wiredImg from '../wired_large_logo.png'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    Redirect
  } from "react-router-dom";
import { storage } from "../services/firebase";
import { ref, getDownloadURL } from "firebase/storage";

function Download() {
    return (
        <div className="doc" role="main">
		
		
			<article>
				<header className="measure">
					<div className="logo-big  m-b-half">
						<img className="two" src={wiredImg} style={{width:"50%"}} />
						<h3>Other ways to install Wired</h3>
					</div>
					<p>If you have a Steam account, the easiest way to install the game on your Mac or Windows PC is through the Steam games portal (see main page). However, if you don't want to do that there are some other ways to do it here. The installation processes is not as well tested, but they ought to work ok - and if they do install, the game should play identically to the Steam version.</p>
					<h2>Windows</h2>
					<p>There are two additional ways to get the game playing on a Windows machine. </p>
					<p><a href="deployment/wired_installer.exe" onClick={(e) => {
                        e.preventDefault()
                        getDownloadURL(ref(storage, 'builds/wired_installer.exe'))
                        .then((url) => {
                            var link = document.createElement("a");
                            if (link.download !== undefined) {
                                link.setAttribute("href", url);
                                link.setAttribute("target", "_blank");
                                link.style.visibility = 'hidden';
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            }
                        })
                        .catch((error) => {
                        });
                    }}>wired_installer.exe</a><br />
					Run this to install Wired on a windows machine. It should work on Windows 7, 8 and 10. It will create a folder on your desktop called CUED and inside you will find a link to the game and also an uninstaller. You can also uninstall the game from the Add/Remove programs section of Control Panel. Look for CUED - Wired in the list of installed programs and click on it to uninstall.<br /></p>
					<p><a href="deployment/wired32_installer.exe" onClick={(e) => {
                        e.preventDefault()
                        getDownloadURL(ref(storage, 'builds/wired32_installer.exe'))
                        .then((url) => {
                            var link = document.createElement("a");
                            if (link.download !== undefined) {
                                link.setAttribute("href", url);
                                link.setAttribute("target", "_blank");
                                link.style.visibility = 'hidden';
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            }
                        })
                        .catch((error) => {
                        });
                    }}>wired_installer32.exe</a><br />
					Same as above but for 32 bit versions of Windows.<br /></p>
					<p><a href="deployment/wired.exe.zip" onClick={(e) => {
                        e.preventDefault()
                        getDownloadURL(ref(storage, 'builds/wired.exe.zip'))
                        .then((url) => {
                            var link = document.createElement("a");
                            if (link.download !== undefined) {
                                link.setAttribute("href", url);
                                link.setAttribute("target", "_blank");
                                link.style.visibility = 'hidden';
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            }
                        })
                        .catch((error) => {
                        });
                    }}>wired.exe.zip</a><br />
					This is a zip file that you can unzip into a folder anywhere on your computer and then just run the wired.exe application inside the folder to run the game.<br /> 
					To remove this version of the game from your computer, just delete the folder you unzipped.<br />
					This zip file is useful if you don’t have permission to run installers on your machine.<br /> 
					Sometimes machines are set up to not allow any executables at all to be run, in which case neither of the above methods will work - you will just need to play the web-browser version (see main page).</p>

<h2>Mac (OSX)</h2>
<p><a href="deployment/wired_installer.dmg" onClick={(e) => {
                        e.preventDefault()
                        getDownloadURL(ref(storage, 'builds/wired_installer.dmg'))
                        .then((url) => {
                            var link = document.createElement("a");
                            if (link.download !== undefined) {
                                link.setAttribute("href", url);
                                link.setAttribute("target", "_blank");
                                link.style.visibility = 'hidden';
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            }
                        })
                        .catch((error) => {
                        });
                    }}>wired_installer.dmg</a></p><br />

<p>Just run this installer to install the game on a Mac. There is a point in the installation where you have to drag the application into the Applications folder as you do in many Mac installers. To remove the game from the computer, just remove the “wired.app” application from the Applications folder.</p>

				</header>
 				
				<div className="wrap">
					<section className="gw">
						
			           
            			<div className="g  g-S-one-third "><br /></div>
						<div className="g  g-S-one-third "><Link className="link-btn" to="/"><button className="btn  btn--push  btn--S  btn--push--3">BACK TO<br /> MAIN PAGE</button></Link></div>
						<div className="g  g-S-one-third "><br /></div>
					</section>
				</div>
			</article>
        
		

		</div>
)
}

export default Download;