import logo from './logo.svg';
import Home from './components/Home'
import Entry from './components/Entry'
import Download from './components/Download'
import wiredImg from './wired_large_logo.png'
import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Redirect
} from "react-router-dom";

function App() {
  return (
    <Router forceRefresh={true}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/entry" element={<Entry />} />
          <Route exact path="/download" element={<Download />} />
        </Routes>
    </Router>
  );
}

export default App;
