import wiredImg from '../wired_large_logo.png'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    Redirect
  } from "react-router-dom";

function Home() {
    return (
  
<div className="doc">
      <article>
				<header className="measure">
					<div className="logo-big  m-b-half">
					  <img className="three" src={wiredImg} />
					</div>
					<p>Wired is an atmospheric puzzle-platform game where you must wire up electrical circuits to get through. It is built on a realistic physical model of electricity and so by playing the game, you will also learn how electricity behaves. It will run on Windows or Mac computers and needs a keyboard and mouse. You will need your sound turned on to play it.</p>
					<p>This page shows a video of the game. You can play the game by using one of the buttons below. The browser version is a quick way to get playing, or for a better playing experience, you can download an installable version from the game distribution site, Steam.<br /></p>
				</header>
 				
 				
				<div className="wrap">
					<section className="gw">
						{<div className="logo-big  m-b-half"><iframe width="640" height="360" src="https://www.youtube.com/embed/7u69gooy5x8?rel=0" frameBorder="10" style={{border: '2px solid gray'}} allowFullScreen></iframe></div>}
						{/*<div className="g  g-S-one-third ">-</div>*/}
						<Link to="/entry"><div className="g  g-S-one-third "><button className="btn  btn--push  btn--S  btn--push--3">WEB BROWSER<br />VERSION</button></div></Link>
						<div className="g  g-S-one-third "><a className="link-btn" href="https://store.steampowered.com/app/885470/Wired/" download><button className="btn  btn--push  btn--S  btn--push--3">DOWNLOAD INSTALLER<br />FROM STEAM</button></a></div>
						<div className="g  g-S-one-third ">-</div>
						{/*<Link to="/entry"><div className="g  g-S-one-third "><button className="btn  btn--push  btn--S  btn--push--3">WEB BROWSER<br />VERSION</button></div></Link>
						<Link to="/download"><div className="g  g-S-one-third "><button className="btn  btn--push  btn--S  btn--push--3">OTHER DOWNLOAD<br />OPTIONS</button></div></Link>*/}

					</section>
				</div>
				
				<header className="measure">
					<p><br />If you want to keep track of Wired-related news, you can follow us on Twitter: @WiredTheGame. Scroll down to see a more in-depth explanation of the game</p>
				</header>
				
				<header className="measure">
					<div className="logo-big  m-b-half">
					<h1><br /></h1> 
					<h1>An Explanation</h1>
					</div>
					<p>This video shows an 8 minute explanation of the game.</p>
					
				</header>
				<div className="wrap">
					<section className="gw">
						{<div className="logo-big  m-b-half"><iframe width="640" height="360" src="https://www.youtube.com/embed/ZXKOxcYuHag?rel=0" frameBorder="10" style={{border: '2px solid gray'}} allowFullScreen></iframe></div>}
					</section>
				</div>				
				<br />
				<br />
				<br />
				
					
			</article>
    </div>)
}

export default Home;