// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDDOZDm10PYkiZYiglr72DXTAs4QYn1fbo",
  authDomain: "wired-324bc.firebaseapp.com",
  projectId: "wired-324bc",
  storageBucket: "wired-324bc.appspot.com",
  messagingSenderId: "295050512976",
  appId: "1:295050512976:web:6b17b0aff4a69112e71f55",
  measurementId: "G-4CBFC3HPY8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const storage = getStorage(app)