import wiredImg from '../wired_large_logo.png'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    Redirect
  } from "react-router-dom";
  import Unity, { UnityContent } from "react-unity-webgl";
  import React, { useEffect } from "react";

function Entry() {
    let unityContent = new UnityContent(
        "/unity/Build/wired.json",
        "/unity/Build/UnityLoader.js"
    );

    
    

    return (
        <div className="doc" >
		
		
        <article>
            <header className="measure">
                <div className="logo-big  m-b-half">
                    <img className="two" src={wiredImg} style={{width: "50%"}} />
                    <h3>Browser version</h3>
                </div>
                <p>This version of the game can be played directly in a web browser in the window below. You will need your browser to be fairly up-to-date. For best results, use Chrome. If you have problems playing this version, try restarting your browser - you can keep playing from where you left off from the Load Game menu.</p>
                <p>The smoothest and most reliable way to play this game is by downloading the installable version from Steam - see the main page for link.</p>
            </header>
             
            <div className="wrap">
                <section className="gw">

                    {/*<div className="logo-big  m-b-half"><iframe width="1016" height="664" src="index_protected.html" style={{border: "2px solid gray"}}></iframe></div>*/}
                    <div className="logo-big  m-b-half"><Unity unityContent={unityContent} /></div>	
                    	
                    <div className="logo-big  m-b-half"><button style={{ width: 100, height: 50 }} onClick={() => {
                        unityContent.setFullscreen(true)
                    }}>Enter Fullscreen</button></div>
                    <div>
                    <div className="g  g-S-one-third "><br /></div>
                    <div className="g  g-S-one-third "><Link className="link-btn" to={'/'}><button className="btn  btn--push  btn--S  btn--push--3">BACK TO<br /> MAIN PAGE</button></Link></div>
                    <div className="g  g-S-one-third "><br /></div>
                    </div>
                </section>
            </div>
        </article>
    
    

    </div>
)
}

export default Entry;